import React, { useState, useEffect } from 'react';
import { IoSearch } from 'react-icons/io5';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { CgScreen } from 'react-icons/cg';
import { useNavigate } from 'react-router-dom';
import api from '../../utils/api';

function ClosedTickets() {
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 15;
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the pending tickets from the API
    const fetchTickets = async () => {
      try {
        const response = await api.get('/admin/ticket?ticket_type=closed');
        if (response.data && response.data.success) {
          setData(response.data.data); // Adjust based on actual response structure
          console.log('Fetched tickets:', response.data.data);
        } else {
          console.error('API Error: Response not successful', response);
        }
      } catch (error) {
        console.error('Error fetching tickets:', error);
      }
    };

    fetchTickets();
  }, []);

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setData((prevData) =>
      prevData.map((row) => ({ ...row, selected: newSelectAll }))
    );
  };

  const handleRowCheckboxChange = (index) => {
    const updatedData = data.map((row, rowIndex) =>
      rowIndex === index ? { ...row, selected: !row.selected } : row
    );
    setData(updatedData);
    setSelectAll(updatedData.every((row) => row.selected));
  };

  const filteredData = data.filter((ticket) =>
    ticket.subject.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const currentPageData = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleTicketsDetails = () => {
    navigate(`/supportticket/ticketdetails`);
  };

  return (
    <>
      <header className="flex justify-between px-6 py-2 mt-2">
        <div>
          <h2 className="text-xl font-bold">Pending Tickets</h2>
        </div>
      </header>
      <div className="mx-auto mt-2 p-4 w-[80vw]">
        <div className="flex justify-between items-center p-4 border-t border-l border-r">
          <div className="w-72 flex items-center gap-2 border px-4 rounded-lg">
            <input
              type="text"
              className="w-full px-4 py-2 rounded-md outline-none"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <IoSearch className="text-2xl" />
          </div>
        </div>
        <div className="overflow-x-auto w-full">
          <table className="table-auto w-full border-collapse border">
            <thead className="border-b">
              <tr>
                <th className="border-b">
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                    className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                  />
                </th>
                <th className="px-4 py-4 flex justify-start text-black text-start font-bold uppercase text-sm">
                  Subject
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Submitted By
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Status
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Priority
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Last Reply
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {currentPageData.map((item, index) => (
                <tr key={index} className="hover:bg-gray-100 border-b">
                  <td className="px-4 py-3 text-md text-center whitespace-nowrap">
                    <input
                      type="checkbox"
                      checked={item.selected}
                      onChange={() => handleRowCheckboxChange(index)}
                      className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                    />
                  </td>
                  <td className="px-2 py-3 text-md text-start pl-4">
                    <div className="flex gap-2">
                      <span className="">{item.subject}</span>
                    </div>
                  </td>
                  <td className="px-2 py-3 text-md text-start pl-4">
                    {item.username}
                  </td>
                  <td className="px-2 py-3 text-md text-start pl-4">
                    <span className="px-5 py-3 rounded-lg bg-red-50 border border-red-600 text-red-600">
                      {item.ticket_status}
                    </span>
                  </td>

                  <td className={`px-2 py-3 text-md text-start pl-4`}>
                    <span
                      className={` ${
                        item.priority === 'High'
                          ? 'text-red-600 border-red-600 bg-red-50 px-5 py-3 rounded-lg border'
                          : item.priority === 'Medium'
                          ? 'text-yellow-600 border-yellow-600 bg-green-50 px-5 py-3 rounded-lg border'
                          : 'text-cyan-600 border-cyan-600 bg-cyan-50 px-5 py-3 rounded-lg border'
                      }`}
                    >
                      {item.priority}
                    </span>
                  </td>

                  <td className="px-2 py-3 text-md text-start pl-4">
                    {item.last_reply}
                  </td>
                  <td className="px-2 py-3 text-sm text-start pl-4">
                    <div className="flex gap-2 flex-wrap">
                      <button
                        onClick={() => handleTicketsDetails()}
                        className={`px-3 py-3 rounded-lg text-xl font-bold bg-gray-50 text-[#288EC7] hover:bg-[#288EC7] hover:text-white`}
                      >
                        <CgScreen />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex items-center justify-between px-8 py-2 border-b border-r border-l">
          <p className="text-gray-600">
            Showing {currentPageData.length} of {filteredData.length} Results
          </p>
          <div className="flex gap-1">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-full"
            >
              <FaAngleLeft />
            </button>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                className={`bg-gray-200 hover:bg-orange-600 text-gray-800 font-bold py-2 px-4 rounded-full $ {
                  currentPage === index + 1 ? 'bg-orange-400 text-white' : ''
                }`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-full"
            >
              <FaAngleRight />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClosedTickets;
