import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoSearch } from 'react-icons/io5';
import { FaAngleLeft, FaAngleRight, FaStar } from 'react-icons/fa';
import { CgScreen } from 'react-icons/cg';
import { BiBox } from 'react-icons/bi';
import api from '../../utils/api';

function Authors() {
  const [data, setData] = useState([]); // State for API data
  const [selectAll, setSelectAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const rowsPerPage = 15; // Maximum number of rows per page
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('/admin/authors/list', {
          headers: { 'Content-Type': 'application/json' },
        });

        if (response.status === 200) {
          const result = await response.data;
          const items =
            result?.message?.['Total Item']?.itemData?.item?.data || [];
          setData(items);
        } else {
          throw new Error(`Failed to fetch data, status: ${response.status}`);
        }
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };
    fetchData();
  }, []);

  // Handle the header checkbox click (select/deselect all)
  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setData(data.map((row) => ({ ...row, selected: newSelectAll })));
  };

  // Handle individual row checkbox click
  const handleRowCheckboxChange = (index) => {
    const updatedData = data.map((row, rowIndex) =>
      rowIndex === index ? { ...row, selected: !row.selected } : row
    );
    setData(updatedData);
    setSelectAll(updatedData.every((row) => row.selected)); // Check if all are selected
  };

  // Render stars based on rating (mock implementation for demo purposes)
  const renderStars = (rating) => {
    const totalStars = 5;
    return (
      <div className="flex justify-center">
        {[...Array(totalStars)].map((_, index) => (
          <FaStar
            key={index}
            className={`text-lg ${
              index < rating ? 'text-yellow-400' : 'text-gray-200'
            }`}
          />
        ))}
      </div>
    );
  };

  // Get current page data
  const currentPageData = data.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  // Handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Navigation handlers
  const handleAdminDetails = (id) => {
    navigate(`/manageauthors/authordetails`);
    // navigate(`/manageauthors/authordetails/${id}`);
  };

  const handleViewDetails = () => {
    navigate(`/payments/allpayments`);
  };

  return (
    <>
      <header className="flex justify-between px-6 py-2 mt-2">
        <div>
          <h2 className="text-xl font-bold">Authors List</h2>
        </div>
      </header>
      <div className="mx-auto mt-2 p-4 w-[80vw] ">
        <div className="flex justify-between items-center p-4 border-t border-l border-r">
          <div className="w-72 flex items-center gap-2 border px-4 rounded-lg">
            <input
              type="text"
              className="w-full px-4 py-2 rounded-md outline-none"
              placeholder="Search..."
            />
            <IoSearch className="text-2xl" />
          </div>
        </div>
        <div className="overflow-x-auto w-full">
          <table className="table-auto w-[90vw] border-collapse border">
            <thead className="border-b">
              <tr>
                <th className="border-b">
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                    className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                  />
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  User
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Email
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Phone
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Country
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Joined At
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Balance
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Rating
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {currentPageData.map((item, index) => (
                <tr key={index} className="hover:bg-gray-100 border-b">
                  <td className="px-4 py-3 text-md text-center whitespace-nowrap">
                    <input
                      type="checkbox"
                      checked={item.selected || false}
                      onChange={() => handleRowCheckboxChange(index)}
                      className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                    />
                  </td>
                  <td className="px-4 py-3 text-md text-start">{item.name}</td>
                  <td className="px-4 py-3 text-md text-start">{item.email}</td>
                  <td className="px-4 py-3 text-md text-start">
                    {item.phone || 'N/A'}
                  </td>
                  <td className="px-4 py-3 text-md text-start">
                    {item.address?.city || 'N/A'}
                  </td>
                  <td className="px-4 py-3 text-md text-start">
                    {item.address?.city || 'N/A'}
                  </td>
                  <td className="px-4 py-3 text-md text-start">
                    {item.address?.city || 'N/A'}
                  </td>
                  <td className="px-4 py-3 text-md text-start">
                    {item.address?.city || 'N/A'}
                  </td>
                  <td className="px-2 py-3 text-sm text-start pl-4">
                    <div className="flex gap-2 flex-wrap">
                      <button
                        onClick={() => handleViewDetails()}
                        className={`px-3 py-3 rounded-lg text-xl font-bold bg-gray-50 text-[#288EC7] hover:bg-[#288EC7] hover:text-white`}
                      >
                        <BiBox />
                      </button>
                      <button
                        onClick={() => handleAdminDetails()}
                        className={`px-3 py-3 rounded-lg text-xl font-bold bg-gray-50 text-[#092C4C] hover:bg-[#092C4C] hover:text-white`}
                      >
                        <CgScreen />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex items-center justify-between px-8 py-2 border-b border-r border-l">
          <p className="text-gray-600">
            Showing {currentPageData.length} of {data.length} Results
          </p>
          <div className="flex gap-1">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-full"
            >
              <FaAngleLeft />
            </button>
            {Array.from(
              { length: Math.ceil(data.length / rowsPerPage) },
              (_, index) => (
                <button
                  key={index}
                  onClick={() => handlePageChange(index + 1)}
                  className={`bg-gray-200 hover:bg-orange-600 text-gray-800 font-bold py-2 px-4 rounded-full ${
                    currentPage === index + 1 ? 'bg-orange-400 text-white' : ''
                  }`}
                >
                  {index + 1}
                </button>
              )
            )}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === Math.ceil(data.length / rowsPerPage)}
              className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-full"
            >
              <FaAngleRight />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Authors;
