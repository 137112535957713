import axios from 'axios';

// Create an axios instance with a base URL and authorization header
const api = axios.create({
  baseURL:
    process.env.REACT_APP_API_BASE_URL || 'https://fickrr.brandeducer.store/api',
    // process.env.REACT_APP_API_BASE_URL || 'https://fickrr.brandeducer.xyz/api',
});

// Add a request interceptor to include the token in headers
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
