import React, { useState, useEffect } from 'react';
import { IoSearch } from 'react-icons/io5';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';
import api from '../../utils/api';

function LoginHistory() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 15;
  const [searchQuery, setSearchQuery] = useState('');
  const [error, setError] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Fetch data from the API
  useEffect(() => {
    const fetchLoginHistory = async () => {
      try {
        const response = await api.get('/admin/login-history');
        const result = response.data; // Adjust for your API structure

        if (result.status === 'success') {
          const formattedData = result.data.map((item) => ({
            selected: false,
            userName: item.user_name,
            loginAtTime: new Date(item.login_at).toLocaleString(),
            ip: item.ip,
            locationCity: item.city,
            locationCountry: item.country,
            browser: item.browser || 'Unknown',
            os: item.os || 'Unknown',
          }));
          setData(formattedData);
        } else {
          throw new Error('Failed to fetch login history');
        }
      } catch (err) {
        setError('Error fetching data. Please try again.');
        console.error('Error:', err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLoginHistory();
  }, []);

  const totalPages = Math.ceil(
    data.filter(
      (item) =>
        item.userName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.loginAtTime.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.locationCity.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.os.toLowerCase().includes(searchQuery.toLowerCase())
    ).length / rowsPerPage
  );

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setData(data.map((row) => ({ ...row, selected: newSelectAll })));
  };

  const handleRowCheckboxChange = (index) => {
    const updatedData = data.map((row, rowIndex) =>
      rowIndex === index ? { ...row, selected: !row.selected } : row
    );
    setData(updatedData);
    setSelectAll(updatedData.every((row) => row.selected));
  };

  const filteredData = data.filter(
    (item) =>
      item.userName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.loginAtTime.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.locationCity.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.os.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const currentPageData = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      <header className="flex justify-between px-6 py-2 mt-2">
        <div>
          <h2 className="text-xl font-bold">User Login History</h2>
        </div>
      </header>
      <div className="mx-auto mt-2 p-4 w-[80vw]">
        <div className="flex justify-between items-center p-4 border-t border-l border-r">
          <div className="w-72 flex items-center gap-2 border px-4 rounded-lg">
            <input
              type="text"
              className="w-full px-4 py-2 rounded-md outline-none"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <IoSearch className="text-2xl" />
          </div>
        </div>
        <div className="overflow-x-auto w-full">
          <table className="table-auto w-full border-collapse border">
            <thead className="border-b">
              <tr>
                <th className="border-b">
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                    className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                  />
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  User
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Login At
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  IP
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  City
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Country
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Browser
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  OS
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan="9" className="text-center py-4 text-gray-500">
                    Loading data...
                  </td>
                </tr>
              ) : currentPageData.length > 0 ? (
                currentPageData.map((item, index) => (
                  <tr key={index} className="hover:bg-gray-100 border-b">
                    <td className="px-4 py-3 text-md text-center whitespace-nowrap">
                      <input
                        type="checkbox"
                        checked={item.selected}
                        onChange={() => handleRowCheckboxChange(index)}
                        className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                      />
                    </td>
                    <td className="px-4 py-3 text-md text-start pl-4">
                      {item.userName}
                    </td>
                    <td className="px-2 py-3 text-md text-start pl-4">
                      {item.loginAtTime}
                    </td>
                    <td className="px-2 py-3 text-md text-start pl-4">
                      {item.ip}
                    </td>
                    <td className="px-2 py-3 text-md text-start pl-4">
                      {item.locationCity}
                    </td>
                    <td className="px-2 py-3 text-md text-start pl-4">
                      {item.locationCountry}
                    </td>
                    <td className="px-2 py-3 text-md text-start pl-4">
                      {item.browser}
                    </td>
                    <td className="px-2 py-3 text-md text-start pl-4">
                      {item.os}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="9" className="text-center py-4 text-gray-500">
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="flex items-center justify-between px-8 py-2 border-b border-r border-l">
          <p className="text-gray-600">
            Showing {currentPageData.length} of {filteredData.length} Results
          </p>
          <div className="flex gap-1">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-full"
            >
              <FaAngleLeft />
            </button>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                className={`bg-gray-200 hover:bg-orange-600 text-gray-800 font-bold py-2 px-4 rounded-full ${
                  currentPage === index + 1 ? 'bg-orange-400 text-white' : ''
                }`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-full"
            >
              <FaAngleRight />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginHistory;
