import React, { useState, useEffect } from 'react';
import { IoSearch } from 'react-icons/io5';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { CgScreen } from 'react-icons/cg';
import api from '../../utils/api';

function ActiveUsers() {
  const [data, setData] = useState([]);
  console.log(data);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(15);
  const [selectAll, setSelectAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const totalPages = Math.ceil(data.length / rowsPerPage);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await api.get('/admin/users?user_status=1');
        console.log('Raw response:', response);

        const result = response.data; // Adjust for your API structure
        console.log('Parsed response:', result);

        if (result.status === 'success') {
          const users = result.data.users.map((user) => ({
            selected: false,
            authorName: user.name || 'N/A',
            authorUsername: `@${user.username || 'N/A'}`,
            email: user.email || 'N/A',
            mobile: user.mobile || 'N/A',
            country: user.country || 'N/A',
            joinedAtTime: user.created_at || 'N/A',
            joinedAtDuration: user.joined_duration || 'N/A',
            balance: user.earnings || '0.00',
          }));
          console.log('Mapped users:', users);
          setData(users);
        } else {
          throw new Error('Failed to fetch users');
        }
      } catch (error) {
        console.error('Error:', error.message);
        setError('Error fetching data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setData(data.map((row) => ({ ...row, selected: newSelectAll })));
  };

  const handleRowCheckboxChange = (index) => {
    const updatedData = data.map((row, rowIndex) =>
      rowIndex === index ? { ...row, selected: !row.selected } : row
    );
    setData(updatedData);
    setSelectAll(updatedData.every((row) => row.selected));
  };

  const currentPageData = data.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        Loading...
      </div>
    );
  }

  return (
    <>
      <header className="flex justify-between px-6 py-2 mt-2">
        <div>
          <h2 className="text-xl font-bold">Mobile Unverified Users</h2>
          <p className="text-gray-500"></p>
        </div>
      </header>
      <div className="mx-auto mt-2 p-4 w-[80vw]">
        <div className="flex justify-between items-center p-4 border-t border-l border-r">
          <div className="w-72 flex items-center gap-2 border px-4 rounded-lg">
            <input
              type="text"
              className="w-full px-4 py-2 rounded-md outline-none"
              placeholder="Search..."
            />
            <IoSearch className="text-2xl" />
          </div>
        </div>
        <div className="overflow-x-auto w-full">
          <table className="table-auto w-full border-collapse border">
            <thead className="border-b">
              <tr>
                <th className="border-b">
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                    className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                  />
                </th>
                <th className="px-4 py-4 text-black font-bold uppercase text-sm">
                  User
                </th>
                <th className="px-4 py-4 text-black font-bold uppercase text-sm">
                  Email
                </th>
                <th className="px-4 py-4 text-black font-bold uppercase text-sm">
                  phone
                </th>
                <th className="px-4 py-4 text-black font-bold uppercase text-sm">
                  Country
                </th>
                <th className="px-4 py-4 text-black font-bold uppercase text-sm">
                  Joined At
                </th>
                <th className="px-4 py-4 text-black font-bold uppercase text-sm">
                  Balance
                </th>
                <th className="px-4 py-4 text-black font-bold uppercase text-sm">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {currentPageData.map((item, index) => (
                <tr key={index} className="hover:bg-gray-100 border-b">
                  <td className="px-4 py-3 text-center whitespace-nowrap">
                    <input
                      type="checkbox"
                      checked={item.selected}
                      onChange={() => handleRowCheckboxChange(index)}
                      className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                    />
                  </td>
                  <td className="px-4 py-3 text-start">
                    <div className="flex flex-col">
                      <span>{item.authorName}</span>
                      {item.authorUsername}
                    </div>
                  </td>
                  <td className="px-4 py-3 text-start">{item.email}</td>
                  <td className="px-4 py-3 text-start">{item.mobile}</td>
                  <td className="px-4 py-3 text-start">{item.country}</td>
                  <td className="px-4 py-3 text-start">
                    <div className="flex flex-col">
                      <span>{item.joinedAtTime}</span>
                      {item.joinedAtDuration}
                    </div>
                  </td>
                  <td className="px-4 py-3 text-start">${item.balance}</td>
                  <td className="px-4 py-3 text-start">
                    <button className="px-3 py-3 rounded-lg text-xl bg-gray-50 text-[#288EC7] hover:bg-[#288EC7] hover:text-white">
                      <CgScreen />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex items-center justify-between px-8 py-2 border">
          <p className="text-gray-600">
            Showing {currentPageData.length} of {data.length} Results
          </p>
          <div className="flex gap-1">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-full"
            >
              <FaAngleLeft />
            </button>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                className={`bg-gray-200 hover:bg-orange-600 text-gray-800 font-bold py-2 px-4 rounded-full ${
                  currentPage === index + 1 ? 'bg-orange-400 text-white' : ''
                }`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-full"
            >
              <FaAngleRight />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ActiveUsers;
