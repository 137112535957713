import React, { useState, useEffect } from 'react';
import { IoSearch } from 'react-icons/io5';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { PiEyeSlash } from 'react-icons/pi';
import { MdOutlineEdit } from 'react-icons/md';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import api from '../../utils/api';

function Reviews() {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [newCategory, setNewCategory] = useState({
    name: '',
    status: 'Inactive',
  });
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 15;
  const [showAlert, setShowAlert] = useState(false);

  // Fetch categories from API
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await api.get(
          '/review-category',

          {
            headers: { 'Content-Type': 'application/json' },
          }
        );
        // 'https://fickrr.brandeducer.store/api/review-category'

        const result = await response.json();
        if (result.success) {
          const formattedData = result.data.map((item) => ({
            id: item.id,
            name: item.name,
            status: item.status === 1 ? 'Active' : 'Inactive',
            selected: false,
          }));
          setData(formattedData);
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  const totalPages = Math.ceil(data.length / rowsPerPage);
  const currentPageData = data.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const toggleStatus = () => {
    setNewCategory({
      ...newCategory,
      status: newCategory.status === 'Active' ? 'Inactive' : 'Active',
    });
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setData(data.map((row) => ({ ...row, selected: newSelectAll })));
  };

  const handleRowCheckboxChange = (index) => {
    const updatedData = data.map((row, rowIndex) =>
      rowIndex === index ? { ...row, selected: !row.selected } : row
    );
    setData(updatedData);
    setSelectAll(updatedData.every((row) => row.selected));
  };

  const handleAddCategory = () => {
    setData([
      ...data,
      {
        id: data.length + 1,
        name: newCategory.name,
        status: newCategory.status,
        selected: false,
      },
    ]);
    setShowModal(false);
    setNewCategory({ name: '', status: 'Inactive' });
  };

  const handleEditCategory = () => {
    const updatedData = [...data];
    updatedData[selectedCategoryIndex] = {
      ...updatedData[selectedCategoryIndex],
      name: newCategory.name,
      status: newCategory.status,
    };
    setData(updatedData);
    setShowEditModal(false);
  };

  const openEditModal = (index) => {
    setSelectedCategoryIndex(index);
    setNewCategory({
      name: data[index].name,
      status: data[index].status,
    });
    setShowEditModal(true);
  };

  const handleAlertButtonClick = () => {
    setShowAlert(true);
  };

  const handleConfirm = () => {
    console.log('Category unfeatured!');
    setShowAlert(false);
  };

  const handleCancel = () => {
    setShowAlert(false);
  };

  return (
    <>
      <header className="flex justify-between px-6 py-2 mt-2">
        <div>
          <h2 className="text-xl font-bold">Reviews</h2>
        </div>
        <button
          onClick={() => setShowModal(true)}
          className="bg-blue-500 hover:bg-blue-600 text-white font-semibold h-min p-3 text-sm rounded-lg"
        >
          <span className="flex items-center gap-2">
            <IoMdAddCircleOutline /> Add New Category
          </span>
        </button>
      </header>
      <div className="mx-auto mt-2 p-4 w-[80vw]">
        <div className="flex justify-between items-center p-4 border">
          <div className="w-72 flex items-center gap-2 border px-4 rounded-lg">
            <input
              type="text"
              className="w-full px-4 py-2 rounded-md outline-none"
              placeholder="Search..."
            />
            <IoSearch className="text-2xl" />
          </div>
        </div>
        <div className="overflow-x-auto w-full">
          <table className="table-auto w-full border-collapse border">
            <thead className="border-b">
              <tr>
                <th>
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                    className="h-4 w-4 text-blue-500 border-gray-300 rounded"
                  />
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Name
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Status
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {currentPageData.map((item, index) => (
                <tr key={index} className="hover:bg-gray-100 border-b">
                  <td className="px-4 py-3 text-md text-center whitespace-nowrap">
                    <input
                      type="checkbox"
                      checked={item.selected}
                      onChange={() => handleRowCheckboxChange(index)}
                      className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                    />
                  </td>
                  <td className="px-2 py-3 text-md text-start  pl-4">
                    <div className="">
                      <span className="">{item.name}</span>
                    </div>
                  </td>
                  <td className="px-2 py-3 text-md text-start  pl-4">
                    <span className="px-5 py-3 rounded-lg bg-yellow-100 border border-yellow-600 text-yellow-600">
                      {item.status}
                    </span>
                  </td>

                  <td className="px-2 py-3 text-sm text-start pl-4">
                    <div className="flex gap-2 justify-start flex-wrap">
                      <button
                        onClick={() => openEditModal(index)}
                        className={`px-3 py-3 rounded-lg text-xl font-bold bg-gray-50 text-[#288EC7] hover:bg-[#288EC7] hover:text-white`}
                      >
                        <MdOutlineEdit />
                      </button>
                      <button
                        onClick={handleAlertButtonClick}
                        className={`px-3 py-3 rounded-lg text-xl font-bold bg-gray-50 text-[#092C4C] hover:bg-[#092C4C] hover:text-white`}
                      >
                        <PiEyeSlash />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Add Category Modal */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
            <div className="flex items-center justify-between py-2">
              <h2 className="text-lg font-bold">Add Review Category</h2>
              <button onClick={() => setShowModal(false)} className="font-bold">
                X
              </button>
            </div>
            <label htmlFor="newCategory" className="font-semibold">
              Name
            </label>
            <input
              type="text"
              htmlFor="newCategory"
              value={newCategory.name}
              onChange={(e) =>
                setNewCategory({ ...newCategory, name: e.target.value })
              }
              className="w-full mb-4 p-2 border rounded"
            />
            <div className="flex justify-end space-x-4">
              <button
                onClick={handleAddCategory}
                className="bg-Select_text hover:bg-Select_text2 text-white px-4 py-2 rounded-lg"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Edit Category Modal */}
      {showEditModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
            <div className="flex items-center justify-between py-2">
              <h2 className="text-lg font-bold mb-4">Edit Review Category</h2>
              <button
                onClick={() => setShowEditModal(false)}
                className="font-bold"
              >
                X
              </button>
            </div>
            <label htmlFor="Category" className="font-semibold">
              Name
            </label>
            <input
              type="text"
              htmlFor="Category"
              value={newCategory.name}
              onChange={(e) =>
                setNewCategory({ ...newCategory, name: e.target.value })
              }
              className="w-full mb-4 p-2 border rounded"
            />
            <div className="flex justify-end space-x-4">
              <button
                onClick={handleEditCategory}
                className="bg-Select_text hover:bg-Select_text2 text-white py-2 px-4 rounded-lg"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {showAlert && (
        <div className="fixed top-0 inset-0 bg-black bg-opacity-50 flex items-start justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-96 mt-10">
            <div className="flex justify-between items-center py-2">
              <h2 className="text-lg font-bold">Confirmation Alert!</h2>
              <button className="font-bold" onClick={handleCancel}>
                X
              </button>
            </div>
            <p className="border-b border-t py-2">
              Are you sure to disable this category?
            </p>
            <div className="flex justify-end mt-4">
              <button
                className="bg-Select_text2 hover:bg-Select_text text-white font-bold py-2 px-4 rounded-lg mr-2"
                onClick={handleCancel}
              >
                No
              </button>
              <button
                className="bg-Select_text hover:bg-Select_text2 text-white font-bold py-2 px-4 rounded-lg"
                onClick={handleConfirm}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Reviews;
