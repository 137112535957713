import React, { useState, useEffect } from 'react';
import { IoCheckmarkDoneSharp } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

export default function SalesAmount() {
  const [salesAmountCount, setSalesAmountCount] = useState('139,987.00');

  // useEffect(() => {
  //   fetch('/api/purchase-count')
  //     .then((response) => response.json())
  //     .then((data) => setSalesAmountCount(data.count));
  // }, []);

  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate('/managesales/allsales');
  };

  return (
    <div
      onClick={handleButtonClick}
      className="bg-white rounded-xl shadow-md py-4 px-8 w-72 flex items-center justify-between cursor-pointer hover:bg-green-50"
    >
      <div className="flex items-center gap-2">
        <div className="w-12 h-12 rounded-full flex items-center justify-center bg-gray-100 border border-green-300 border-l-[3px] text-green-500 text-xl">
          <IoCheckmarkDoneSharp />
        </div>
        <div className="flex flex-col">
          <span className="text-xl font-bold">$ {salesAmountCount}</span>
          <span className="text-gray-500">Sales Amount</span>
        </div>
      </div>
    </div>
  );
}
