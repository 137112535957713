import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { HiOutlineUserGroup } from 'react-icons/hi2';
import api from '../../utils/api';

export default function TotalUsers() {
  const [data, setData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('/admin/dashboard'); // Use the custom `api` instance
        console.log('response', response.data);
        setData(response.data.message);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleButtonClick = () => {
    navigate('/manageusers/allusers');
  };

  return (
    <div
      onClick={handleButtonClick}
      className="bg-white rounded-xl shadow-md py-4 px-8 w-72 flex items-center justify-between cursor-pointer hover:bg-green-50"
    >
      <div className="flex items-center gap-2">
        <div className="w-12 h-12 rounded-full flex items-center justify-center bg-gray-100 border border-green-300 border-l-[3px] text-green-500 text-xl ">
          <HiOutlineUserGroup />
        </div>
        <div className="flex flex-col">
          <span className="text-xl font-bold">
            {data ? data['Total Vendors'] : 'Loading...'}
          </span>
          <span className="text-gray-500">Total Users</span>
        </div>
      </div>
    </div>
  );
}
