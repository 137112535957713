import React, { useState, useEffect } from 'react';
import { IoSearch } from 'react-icons/io5';
import { FaAngleLeft, FaAngleRight, FaStar } from 'react-icons/fa6';
import { PiEyeBold } from 'react-icons/pi';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { IoMdAddCircleOutline } from 'react-icons/io';
import api from '../../utils/api';

function Reviews() {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const [name, setName] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 15;
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showReviewAlert, setShowReviewAlert] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);

  // Fetch data from API when the component mounts

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('/admin/rating');
        const { item, current_page, total_pages } = response.data.data;

        if (item) {
          setData(item.map((user) => ({ ...user, selected: false })));
          setCurrentPage(current_page);
          console.log('Fetched item:', item);
        } else {
          console.error('No user data found in response');
        }
      } catch (error) {
        console.error('Failed to fetch data:', error.message);
        alert('Failed to load reviews. Please try again.');
      }
    };

    fetchData();
  }, [currentPage]);

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setData(data.map((row) => ({ ...row, selected: newSelectAll })));
  };

  const handleRowCheckboxChange = (index) => {
    const updatedData = data.map((row, rowIndex) =>
      rowIndex === index ? { ...row, selected: !row.selected } : row
    );
    setData(updatedData);
    setSelectAll(updatedData.every((row) => row.selected));
  };

  const renderStars = (rating) => {
    const totalStars = 5;
    return (
      <div className="flex justify-center">
        {[...Array(totalStars)].map((_, index) => (
          <FaStar
            key={index}
            className={`text-lg ${
              index < rating ? 'text-yellow-400' : 'text-gray-200'
            } `}
          />
        ))}
      </div>
    );
  };

  const currentPageData = data.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );
  const totalPages = Math.ceil(data.length / rowsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleDeleteAlertButtonClick = () => {
    setShowDeleteAlert(true);
  };

  const handleConfirm = () => {
    console.log('Category unfeatured!');
    setShowDeleteAlert(false);
  };

  const handleCancel = () => {
    setShowDeleteAlert(false);
  };

  const handleViewReviewClick = (index) => {
    setSelectedReview(data[index]);
    setShowReviewAlert(true);
  };

  const handleCloseReviewAlert = () => {
    setShowReviewAlert(false);
    setSelectedReview(null);
  };

  return (
    <>
      <header className="flex justify-between px-6 py-2 mt-2">
        <div>
          <h2 className="text-xl font-bold">Reviews</h2>
          <p className="text-gray-500"></p>
        </div>
      </header>
      <div className="mx-auto mt-2 p-4 w-[80vw] ">
        <div className="flex justify-between items-center p-4 border-t border-l border-r">
          <div className="w-72 flex items-center gap-2 border px-4 rounded-lg">
            <input
              type="text"
              className="w-full px-4 py-2 rounded-md outline-none"
              placeholder="Search..."
            />
            <IoSearch className="text-2xl" />
          </div>
        </div>
        <div className="overflow-x-auto w-full">
          <table className="table-auto w-full  border-collapse border">
            <thead className="border-b">
              <th className="border-b">
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll}
                  className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                />
              </th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm e">
                Product
              </th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                Rating
              </th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                Created At
              </th>
              <th className="px-4 py-4 me-6 text-black text-start font-bold uppercase text-sm">
                Action
              </th>
            </thead>
            <tbody>
              {currentPageData.map((item, index) => (
                <tr key={index} className="hover:bg-gray-100 border-b">
                  <td className="px-4 py-3 text-md text-center whitespace-nowrap">
                    <input
                      type="checkbox"
                      checked={item.selected}
                      onChange={() => handleRowCheckboxChange(index)}
                      className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                    />
                  </td>
                  <td className="px-2 py-3 text-md text-start pl-4">
                    {item.name}
                  </td>
                  <td className="px-2 py-3 text-md text-start">
                    {renderStars(item.rating)}
                  </td>
                  <td className="px-2 py-3 text-md text-start pl-4">
                    {item.createdAt}
                  </td>
                  <td className="px-2 py-3 text-sm text-start pl-4">
                    <div className="flex gap-2 flex-wrap">
                      <button
                        onClick={() => handleViewReviewClick(index)}
                        className={`px-3 py-3 rounded-lg text-xl font-bold bg-gray-50 text-[#288EC7] hover:bg-[#288EC7] hover:text-white`}
                      >
                        <PiEyeBold />
                      </button>
                      <button
                        onClick={handleDeleteAlertButtonClick}
                        className={`px-3 py-3 rounded-lg text-xl font-bold bg-gray-50 text-[#FF0404] hover:bg-[#FF0404] hover:text-white`}
                      >
                        <RiDeleteBin5Line />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex items-center justify-between px-8 py-2 border-b border-r border-l">
          <p className="text-gray-600">
            Showing {currentPageData.length} of {data.length} Results
          </p>
          <div className="flex gap-1">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-full"
            >
              <FaAngleLeft />
            </button>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                className={`bg-gray-200 hover:bg-orange-600 text-gray-800 font-bold py-2 px-4 rounded-full ${
                  currentPage === index + 1 ? 'bg-orange-400 text-white' : ''
                }`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-full"
            >
              <FaAngleRight />
            </button>
          </div>
        </div>
      </div>
      {showDeleteAlert && (
        <div className="fixed top-0 inset-0 bg-black bg-opacity-50 flex items-start justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-96 mt-10">
            <div className="flex justify-between items-center">
              <h2 className="text-lg font-semibold">Confirm Deletion</h2>
              <button onClick={handleCancel} className="text-2xl text-gray-600">
                &times;
              </button>
            </div>
            <p className="my-4">
              Are you sure you want to unfeature this category?
            </p>
            <div className="flex justify-end gap-4">
              <button
                onClick={handleCancel}
                className="px-4 py-2 bg-gray-300 rounded-lg hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirm}
                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
      {showReviewAlert && (
        <div className="fixed top-0 inset-0 bg-black bg-opacity-50 flex items-start justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-96 mt-10">
            <div className="flex justify-between items-center">
              <h2 className="text-lg font-semibold">Review Details</h2>
              <button
                onClick={handleCloseReviewAlert}
                className="text-2xl text-gray-600"
              >
                &times;
              </button>
            </div>
            {selectedReview && (
              <div>
                <p>
                  <strong>Product:</strong> {selectedReview.product}
                </p>
                <p>
                  <strong>Rating:</strong> {renderStars(selectedReview.rating)}
                </p>
                <p>
                  <strong>Review:</strong> {selectedReview.review}
                </p>
                <p>
                  <strong>Created At:</strong> {selectedReview.createdAt}
                </p>
              </div>
            )}
            <div className="flex justify-end gap-4 mt-4">
              <button
                onClick={handleCloseReviewAlert}
                className="px-4 py-2 bg-gray-300 rounded-lg hover:bg-gray-400"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Reviews;
