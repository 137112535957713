import React, { useState, useEffect } from 'react';
import { IoSearch } from 'react-icons/io5';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';
import { IoMdAddCircleOutline } from 'react-icons/io';

import api from '../../utils/api';

function SaleItems() {
  const [data, setData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const rowsPerPage = 10; // Maximum number of rows per page
  const totalPages = Math.ceil(data.length / rowsPerPage); // Calculate total pages

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('/admin/sales/sale-items'); // Use your API endpoint here
        console.log('response', response.data);

        // Assuming `response.data.message['Total Item'].itemData.item.data` is the path to the array
        const items = response.data.message['Total Item'].itemData.item.data;
        const formattedData = items.map((item) => ({
          selected: false,
          purchaseToken: item.purchase_token,
          username: item.username,
          vendorAmount: item.vendor_amount,
          adminAmount: item.admin_amount,
          processingFee: item.processing_fee,
          vatPrice: item.vat_price,
          currencyType: item.currency_type,
          paymentType: item.payment_type,
          paymentToken: item.payment_token,
          paymentStatus: item.payment_status,
        }));
        setData(formattedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  // Function to handle the header checkbox click
  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setData(data.map((row) => ({ ...row, selected: newSelectAll })));
  };

  // Function to handle individual row checkbox click
  const handleRowCheckboxChange = (index) => {
    const updatedData = data.map((row, rowIndex) =>
      rowIndex === index ? { ...row, selected: !row.selected } : row
    );
    setData(updatedData);
    setSelectAll(updatedData.every((row) => row.selected));
  };

  // Get current page data
  const currentPageData = data.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  // Handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      <header className="flex justify-between px-6 py-2 mt-2">
        <div>
          <h2 className="text-xl font-bold">Sale Items</h2>
        </div>
      </header>
      <div className="mx-auto mt-2 p-4 w-[80vw]">
        <div className="flex justify-between items-center p-4 border-t border-l border-r">
          <div className="w-72 flex items-center gap-2 border px-4 rounded-lg">
            <input
              type="text"
              className="w-full px-4 py-2 rounded-md outline-none"
              placeholder="Search..."
            />
            <IoSearch className="text-2xl" />
          </div>
        </div>
        <div className="overflow-x-auto w-full">
          <table className="table-auto w-[95vw] border-collapse border">
            <thead className="border-b">
              <tr>
                <th className="border-b">
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                    className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                  />
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Purchase Token
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Username
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Vendor Amount
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Processing Fee
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  VAT Price
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Payment Type
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Payment Status
                </th>
              </tr>
            </thead>
            <tbody>
              {currentPageData.map((item, index) => (
                <tr key={index} className="hover:bg-gray-100 border-b">
                  <td className="px-4 py-3 text-md text-center whitespace-nowrap">
                    <input
                      type="checkbox"
                      checked={item.selected}
                      onChange={() => handleRowCheckboxChange(index)}
                      className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                    />
                  </td>
                  <td className="px-4 py-3 text-md text-start">
                    {item.purchaseToken}
                  </td>
                  <td className="px-4 py-3 text-md text-start">
                    {item.username}
                  </td>
                  <td className="px-4 py-3 text-md text-start">
                    {item.currencyType} {item.vendorAmount}
                  </td>
                  <td className="px-4 py-3 text-md text-start">
                    {item.currencyType} {item.processingFee}
                  </td>
                  <td className="px-4 py-3 text-md text-start">
                    {item.currencyType} {item.vatPrice}
                  </td>
                  <td className="px-4 py-3 text-md text-start">
                    {item.paymentType}
                  </td>
                  <td className="px-4 py-3 text-md text-start">
                    <button
                      className={`px-3 py-1 rounded-lg text-sm font-medium ${
                        item.paymentStatus === 'completed'
                          ? 'bg-green-100 text-green-600 border border-green-500'
                          : 'bg-red-100 text-red-600 border border-red-500'
                      }`}
                    >
                      {item.paymentStatus}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex items-center justify-between px-8 py-2 border-b border-r border-l">
          <p className="text-gray-600">
            Showing {currentPageData.length} of {data.length} Results
          </p>
          <div className="flex gap-1">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-full"
            >
              <FaAngleLeft />
            </button>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                className={`bg-gray-200 hover:bg-orange-600 text-gray-800 font-bold py-2 px-4 rounded-full ${
                  currentPage === index + 1 ? 'bg-orange-400 text-white' : ''
                }`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-full"
            >
              <FaAngleRight />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default SaleItems;
