import React, { useState, useEffect } from 'react';
import { FaPercent } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';

export default function SellerCharge() {
  const [sellerChargeCount, setSellerChargeCount] = useState('39,044.00');

  // useEffect(() => {
  //   fetch('/api/purchase-count')
  //     .then((response) => response.json())
  //     .then((data) => setSellerChargeCount(data.count));
  // }, []);

  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate('/report/transactionhistory');
  };

  return (
    <div
      onClick={handleButtonClick}
      className="bg-white rounded-xl shadow-md py-4 px-8 w-72 flex items-center justify-between cursor-pointer hover:bg-cyan-50"
    >
      <div className="flex items-center gap-2">
        <div className="w-12 h-12 rounded-full flex items-center justify-center bg-gray-100 border border-cyan-300 border-l-[3px] text-cyan-500 text-xl">
          <FaPercent />
        </div>
        <div className="flex flex-col">
          <span className="text-xl font-bold">$ {sellerChargeCount}</span>
          <span className="text-gray-500">Seller Charge</span>
        </div>
      </div>
    </div>
  );
}
