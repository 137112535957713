import React, { useState, useEffect } from 'react';
import { IoSearch } from 'react-icons/io5';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';
import { CgScreen } from 'react-icons/cg';
import { useNavigate } from 'react-router-dom';
import api from '../../utils/api';

function PendingWithdrawals() {
  const [isLoading, setIsLoading] = useState(true); // To handle loading state
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectAll, setSelectAll] = useState(false);

  const [error, setError] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const rowsPerPage = 15;
  const totalPages = Math.ceil(data.length / rowsPerPage);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPayments = async () => {
      setIsLoading(true);
      try {
        const response = await api.get(
          '/admin/withdrawals?payment_type=pending'
        );
        console.log('response', response);
        const result = response.data; // Adjust for your API structure
        console.log('result', result);
        if (result.message === 'payments fetched successfully') {
          const payments = result.payments.map((payment) => ({
            selected: false,
            gateway: payment.provider || 'Unknown',
            transaction: payment.item_token || 'N/A',
            authorName: payment.name || 'N/A',
            authorUsername: `@${payment.username || 'N/A'}`,
            joinedAtTime: payment.start_date || 'N/A',
            joinedAtDuration: payment.end_date || 'N/A',
            amount: payment.item_price || '0.00',
            charge: payment.discount_price || '0.00',
            conversionRateFrom: '1.00',
            conversionRateTo: '1.00',
            conversionTo: payment.currency_type_code || 'USD',
            conversionFrom: payment.currency_type || '$',
            status: payment.order_status || 'Pending',
          }));
          console.log('payments', payments);
          setData(payments);
        } else {
          throw new Error('Failed to fetch payments');
        }
      } catch (error) {
        setError('Error fetching data. Please try again.');
        console.error('Error:', error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPayments();
  }, []);

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setData(data.map((row) => ({ ...row, selected: newSelectAll })));
  };

  const handleRowCheckboxChange = (index) => {
    const updatedData = data.map((row, rowIndex) =>
      rowIndex === index ? { ...row, selected: !row.selected } : row
    );
    setData(updatedData);
    setSelectAll(updatedData.every((row) => row.selected));
  };
  // Filter data based on search query
  const filteredData = data.filter(
    (item) =>
      item.gateway.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.transaction.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.authorName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.status.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const currentPageData = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handlePageChange = (newPage) => setCurrentPage(newPage);

  const handleWithdrawalsDetails = () => {
    navigate(`/withdrawals/withdrawaldetails`);
  };

  return (
    <>
      <header className="flex justify-between px-6 py-2 mt-2">
        <div>
          <h2 className="text-xl font-bold">Pending Withdrawals </h2>
        </div>
      </header>
      <div className="mx-auto mt-2 p-4 w-[80vw]">
        <div className="flex justify-between items-center p-4 border-t border-l border-r">
          <div className="w-72 flex items-center gap-2 border px-4 rounded-lg">
            <input
              type="text"
              className="w-full px-4 py-2 rounded-md outline-none"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <IoSearch className="text-2xl" />
          </div>
        </div>
        <div className="overflow-x-auto w-full ">
          <table className="table-auto w-full border-collapse border ">
            <thead className="border-b px-5">
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll}
                  className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                />
              </th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm ">
                Gateway
              </th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm ">
                Transaction
              </th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                Initiated
              </th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                {' '}
                User
              </th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                Amount
              </th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                Conversion
              </th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                Status
              </th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                Action
              </th>
            </thead>
            <tbody className="px-5">
              {isLoading ? (
                <tr>
                  <td colSpan="9" className="text-center py-4 text-gray-500">
                    Loading data...
                  </td>
                </tr>
              ) : currentPageData.length > 0 ? (
                currentPageData.map((item, index) => (
                  <tr key={index} className="hover:bg-gray-100 border-b px-5">
                    <td className="px-2 py-3 text-md text-start pl-4">
                      <input
                        type="checkbox"
                        checked={item.selected}
                        onChange={() => handleRowCheckboxChange(index)}
                        className=" h-4 w-4 text-Select_text border-gray-300 rounded"
                      />
                    </td>
                    <td className="px-2 py-3 text-md text-start pl-4">
                      {item.gateway || 'N/A'}
                    </td>
                    <td className="px-2 py-3 text-md text-start pl-4">
                      {item.transaction || 'N/A'}
                    </td>
                    <td className="px-2 py-3 text-md text-start pl-4">
                      <div>
                        {item.authorName || 'N/A'} {item.authorUsername || ''}
                      </div>
                    </td>
                    <td className="px-2 py-3 text-md text-start pl-4">
                      <div>
                        {item.joinedAtTime || 'N/A'} -{' '}
                        {item.joinedAtDuration || 'N/A'}
                      </div>
                    </td>
                    <td className="px-2 py-3 text-md text-start pl-4">
                      ${item.amount || '0.00'}
                    </td>
                    <td className="px-4 py-3 text-md text-start pl-4">
                      {item.conversionFrom || 'N/A'}{' '}
                      {item.conversionRateFrom || 'N/A'} =
                      {item.conversionRateTo || 'N/A'}{' '}
                      {item.conversionTo || 'N/A'}
                    </td>
                    <td className="px-2 py-3 text-md text-start pl-4">
                      <span
                        className={`px-5 py-3 rounded-lg border ${
                          item.status === 'pending'
                            ? 'bg-yellow-50 border-yellow-600 text-yellow-600'
                            : item.status === 'approved' ||
                              item.status === 'completed'
                            ? 'bg-green-50 border-green-600 text-green-600'
                            : item.status === 'rejected' ||
                              item.status === 'initiated'
                            ? 'bg-red-50 border-red-600 text-red-600'
                            : 'bg-gray-50 border-gray-600 text-gray-600'
                        }`}
                      >
                        {item.status || 'N/A'}
                      </span>
                    </td>
                    <td className="px-2 py-3 text-md text-start pl-4">
                      <button
                        onClick={() => handleWithdrawalsDetails(item)}
                        className="px-3 py-3 rounded-lg bg-gray-50 text-[#288EC7] hover:bg-[#288EC7] hover:text-white"
                      >
                        <CgScreen />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="9" className="text-center py-4 text-gray-500">
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="flex items-center justify-between px-8 py-2 border">
          <p className="text-gray-600">
            Showing {currentPageData.length} of {filteredData.length} Results
          </p>
          <div className="flex gap-1">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-full"
            >
              <FaAngleLeft />
            </button>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                className={`bg-gray-200 hover:bg-orange-600 text-gray-800 font-bold py-2 px-4 rounded-full ${
                  currentPage === index + 1 ? 'bg-orange-400 text-white' : ''
                }`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-full"
            >
              <FaAngleRight />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default PendingWithdrawals;
