import React, { useEffect, useState } from 'react';
import { IoSearch } from 'react-icons/io5';
import { FaAngleLeft, FaAngleRight, FaStar } from 'react-icons/fa6';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { MdOutlineEdit } from 'react-icons/md';
import { FaList } from 'react-icons/fa';
import api from '../../utils/api';

function SubCategories() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [newCategory, setNewCategory] = useState({
    name: '',
    img: '',
    status: 'Inactive',
  });
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(null);
  const [selectAll, setSelectAll] = useState(false);

  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const rowsPerPage = 30; // Maximum number of rows per page
  const totalPages = Math.ceil(filteredData.length / rowsPerPage); // Calculate total pages

  // Get current page data
  const currentPageData = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  useEffect(() => {
    // Fetch categories from the API
    const fetchData = async () => {
      try {
        const response = await api.get('/sub-category');
        if (response.data.success) {
          const formattedData = response.data.data.map((item) => ({
            selected: false,
            categorieImg:
              'https://cdn-icons-png.flaticon.com/128/2721/2721652.png', // Placeholder image
            categorieName: item.category_name,
            SubcategorieName: item.subcategory_name,
            status: item.category_status === 1 ? 'Active' : 'Inactive',
            featured: 'Yes',
          }));
          setData(formattedData);
          setFilteredData(formattedData);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  // Handle search
  useEffect(() => {
    const lowercasedQuery = searchQuery.toLowerCase();
    setFilteredData(
      data.filter((item) =>
        item.categorieName.toLowerCase().includes(lowercasedQuery)
      )
    );
    setCurrentPage(1); // Reset to first page when search query changes
  }, [searchQuery, data]);

  // Handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Function to handle the header checkbox click
  const handleSelectAll = () => {
    const newSelectAll = !selectAll; // Toggle the selectAll state
    setSelectAll(newSelectAll);
    setFilteredData(
      filteredData.map((row) => ({ ...row, selected: newSelectAll }))
    ); // Update all rows with the new selection state
  };

  // Function to handle individual row checkbox click
  const handleRowCheckboxChange = (index) => {
    const updatedData = filteredData.map((row, rowIndex) =>
      rowIndex === index ? { ...row, selected: !row.selected } : row
    );
    setFilteredData(updatedData);
    setSelectAll(updatedData.every((row) => row.selected)); // If all checkboxes are selected, set selectAll to true
  };

  const toggleStatus = () => {
    setNewCategory({
      ...newCategory,
      status: newCategory.status === 'Active' ? 'Inactive' : 'Active',
    });
  };

  const handleAddCategory = () => {
    setData([
      ...data,
      {
        selected: false,
        categorieImg: newCategory.img,
        categorieName: newCategory.name,
        status: newCategory.status,
        featured: 'Yes',
      },
    ]);
    setShowModal(false);
    setNewCategory({ name: '', img: '', status: 'Inactive' });
  };

  const handleEditCategory = () => {
    const updatedData = [...data];
    updatedData[selectedCategoryIndex] = {
      ...updatedData[selectedCategoryIndex],
      categorieImg: newCategory.img,
      categorieName: newCategory.name,
      status: newCategory.status,
    };
    setData(updatedData);
    setShowEditModal(false);
  };

  const openEditModal = (index) => {
    setSelectedCategoryIndex(index);
    setNewCategory({
      name: data[index].categorieName,
      img: data[index].categorieImg,
      status: data[index].status,
    });
    setShowEditModal(true);
  };

  return (
    <>
      <header className="flex justify-between px-6 py-2 mt-2">
        <div>
          <h2 className="text-xl font-bold">SubCategories</h2>
          <p className="text-gray-500"></p>
        </div>
        <div className="flex space-x-4">
          <button
            onClick={() => setShowModal(true)}
            className="bg-Select_text hover:bg-Select_text2 text-white font-semibold h-min p-3 text-sm rounded-lg"
          >
            <span className="flex items-center gap-2">
              <IoMdAddCircleOutline /> Add New Categories
            </span>
          </button>
        </div>
      </header>
      <div className="mx-auto mt-2 p-4 w-[80vw] ">
        <div className="flex justify-between items-center p-4 border-t border-l border-r">
          <div className="w-72 flex items-center gap-2 border px-4 rounded-lg">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full px-4 py-2 rounded-md outline-none"
              placeholder="Search..."
            />
            <IoSearch className="text-2xl" />
          </div>
        </div>
        <div className="overflow-x-auto w-full">
          <table className="table-auto w-full  border-collapse border">
            <thead className="border-b">
              <th className="border-b">
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll}
                  className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                />
              </th>

              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                Categories
              </th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                Sub Categories
              </th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                Status
              </th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                Action
              </th>
            </thead>
            <tbody>
              {currentPageData.map((item, index) => (
                <tr key={index} className="hover:bg-gray-100 border-b">
                  <td className="px-4 py-3 text-md text-center whitespace-nowrap">
                    <input
                      type="checkbox"
                      checked={item.selected}
                      onChange={() => handleRowCheckboxChange(index)}
                      className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                    />
                  </td>

                  <td className="px-2 py-3 text-md text-start pl-4">
                    {item.categorieName}
                  </td>
                  <td className="px-2 py-3 text-md text-start pl-4 ">
                    <div className="">
                      <span className="">{item.SubcategorieName}</span>
                    </div>
                  </td>
                  <td className="px-2 py-3 text-md text-start pl-4">
                    <span className="px-5 py-3 rounded-lg bg-green-100 border border-green-600 text-green-600">
                      {item.status}
                    </span>
                  </td>

                  <td className="px-2 py-3 text-sm text-start pl-4">
                    <div className="flex gap-2 justify-start flex-wrap">
                      <button
                        onClick={() => openEditModal(index)}
                        className={`px-3 py-3 rounded-lg text-xl font-bold bg-gray-50 text-[#288EC7] hover:bg-[#288EC7] hover:text-white`}
                      >
                        <MdOutlineEdit />
                      </button>
                      <button
                        className={`px-3 py-3 rounded-lg text-xl font-bold bg-gray-50 text-[#092C4C] hover:bg-[#092C4C] hover:text-white`}
                      >
                        <FaList />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex items-center justify-between px-8 py-2 border-b border-r border-l">
        <p className="text-gray-600">
          Showing {currentPageData.length} of {data.length} Results
        </p>
        <div className="flex gap-1">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-full"
          >
            <FaAngleLeft />
          </button>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(index + 1)}
              className={`bg-gray-200 hover:bg-orange-600 text-gray-800 font-bold py-2 px-4 rounded-full ${
                currentPage === index + 1 ? 'bg-orange-400 text-white' : ''
              }`}
            >
              {index + 1}
            </button>
          ))}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-full"
          >
            <FaAngleRight />
          </button>
        </div>
      </div>

      {/* Add Category Modal */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
            <div className="flex items-center justify-between py-2">
              <h2 className="text-lg font-bold">Add New Subcategory</h2>
              <button onClick={() => setShowModal(false)} className="font-bold">
                X
              </button>
            </div>
            <label htmlFor="Category" className="font-semibold">
              Category
            </label>
            <select
              id="Category"
              value={newCategory.name}
              onChange={(e) =>
                setNewCategory({ ...newCategory, name: e.target.value })
              }
              className="w-full mb-4 p-2 border rounded"
            >
              <option value="" disabled>
                Select Category
              </option>{' '}
              {/* Placeholder */}
              <option value="PHP Scripts">PHP Scripts</option>
              <option value="WordPress">WordPress</option>
              <option value="Flutter">Flutter</option>
              <option value="HTML5">HTML5</option>
              <option value="User Interface">User Interface</option>
              <option value="Javascript">Javascript</option>
            </select>
            <label htmlFor="newCategory" className="font-semibold">
              Name
            </label>
            <input
              type="text"
              htmlFor="name"
              value={newCategory.name}
              onChange={(e) =>
                setNewCategory({ ...newCategory, name: e.target.value })
              }
              className="w-full mb-4 p-2 border rounded"
            />
            <div>
              <h1 className="font-semibold">Status</h1>
              <button
                onClick={toggleStatus}
                className={`px-4 py-2 rounded-md mb-4 ${
                  newCategory.status === 'Active'
                    ? 'bg-Select_text'
                    : 'bg-Select_text2'
                } text-white`}
              >
                {newCategory.status}
              </button>
            </div>
            <div className="flex justify-end space-x-4">
              <button
                onClick={handleAddCategory}
                className="bg-Select_text hover:bg-Select_text2 text-white px-4 py-2 rounded-lg"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Edit Category Modal */}
      {showEditModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
            <div className="flex items-center justify-between py-2">
              <h2 className="text-lg font-bold mb-4">Edit Subcategory</h2>
              <button
                onClick={() => setShowEditModal(false)}
                className="font-bold"
              >
                X
              </button>
            </div>
            <label htmlFor="Category" className="font-semibold">
              Category
            </label>
            <select
              id="Category"
              value={newCategory.name}
              onChange={(e) =>
                setNewCategory({ ...newCategory, name: e.target.value })
              }
              className="w-full mb-4 p-2 border rounded"
            >
              <option value="" disabled>
                Select Category
              </option>{' '}
              {/* Placeholder */}
              <option value="PHP Scripts">PHP Scripts</option>
              <option value="WordPress">WordPress</option>
              <option value="Flutter">Flutter</option>
              <option value="HTML5">HTML5</option>
              <option value="User Interface">User Interface</option>
              <option value="Javascript">Javascript</option>
            </select>
            <label htmlFor="newCategory" className="font-semibold">
              Name
            </label>
            <input
              type="text"
              htmlFor="name"
              value={newCategory.name}
              onChange={(e) =>
                setNewCategory({ ...newCategory, name: e.target.value })
              }
              className="w-full mb-4 p-2 border rounded"
            />
            <h1 className="font-semibold">Status</h1>
            <button
              onClick={toggleStatus}
              className={`px-4 py-2 rounded-md mb-4 ${
                newCategory.status === 'Active'
                  ? 'bg-Select_text'
                  : 'bg-Select_text2'
              } text-white`}
            >
              {newCategory.status}
            </button>
            <div className="flex justify-end space-x-4">
              <button
                onClick={handleEditCategory}
                className="bg-Select_text hover:bg-Select_text2 text-white py-2 px-4 rounded-lg"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SubCategories;
