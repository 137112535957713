import React, { useState, useEffect } from 'react';
import { IoSearch } from 'react-icons/io5';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { CgScreen } from 'react-icons/cg';
import api from '../../utils/api';

function AllUsers() {
  const [data, setData] = useState([]); // Store the data from API
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const [selectAll, setSelectAll] = useState(false); // Track select-all checkbox state
  const [totalPages, setTotalPages] = useState(1); // Total number of pages

  // Fetch the data from the API
  useEffect(() => {
    api
      .get('/admin/users', {
        params: { page: currentPage },
      })
      .then((response) => {
        const { users, current_page, total_pages } = response.data.data;
        setData(users.map((user) => ({ ...user, selected: false }))); // Add a `selected` property to each user
        setCurrentPage(current_page);
        setTotalPages(total_pages);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [currentPage]);

  // Handle select-all checkbox click
  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setData(data.map((row) => ({ ...row, selected: newSelectAll })));
  };

  // Handle individual row checkbox click
  const handleRowCheckboxChange = (index) => {
    const updatedData = data.map((row, rowIndex) =>
      rowIndex === index ? { ...row, selected: !row.selected } : row
    );
    setData(updatedData);
    setSelectAll(updatedData.every((row) => row.selected)); // Check if all rows are selected
  };

  // Handle page change
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <>
      <header className="flex justify-between px-6 py-2 mt-2 whitespace-nowrap">
        <div>
          <h2 className="text-xl font-bold">All Users</h2>
          <p className="text-gray-500"></p>
        </div>
      </header>
      <div className="mx-auto mt-2 p-4 w-[80vw]">
        <div className="flex justify-between items-center p-4 border-t border-l border-r">
          <div className="w-72 flex items-center gap-2 border px-4 rounded-lg">
            <input
              type="text"
              className="w-full px-4 py-2 rounded-md outline-none"
              placeholder="Search..."
            />
            <IoSearch className="text-2xl" />
          </div>
        </div>
        <div className="overflow-x-auto w-full">
          <table className="table-auto w-full border-collapse border">
            <thead className="border-b">
              <th className="border-b">
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll}
                  className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                />
              </th>
              <th className="px-4 py-4 flex justify-start text-black text-start font-bold uppercase text-sm">
                User
              </th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                Email
              </th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                Phone
              </th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                Country
              </th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                Joined At
              </th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                Balance
              </th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                Action
              </th>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index} className="hover:bg-gray-100 border-b">
                  <td className="px-4 py-3 text-md text-center whitespace-nowrap">
                    <input
                      type="checkbox"
                      checked={item.selected}
                      onChange={() => handleRowCheckboxChange(index)}
                      className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                    />
                  </td>
                  <td className="px-4 py-3 text-md text-start pl-4">
                    <div className="flex items-start">
                      <div className="flex flex-col">
                        <span>{item.name}</span>
                        {item.username}
                      </div>
                    </div>
                  </td>
                  <td className="px-2 py-3 text-md text-start pl-4">
                    {item.email || 'N/A'}
                  </td>
                  <td className="px-2 py-3 text-md text-start pl-4">
                    {item.mobile || 'N/A'}
                  </td>
                  <td className="px-2 py-3 text-md text-start pl-4">
                    {item.country || 'N/A'}
                  </td>
                  <td className="px-2 py-3 text-md text-start pl-4">
                    <div className="flex flex-col">
                      <span>{item.created_at}</span>
                    </div>
                  </td>
                  <td className="px-2 py-3 text-md text-start pl-4">
                    ${item.earnings}
                  </td>
                  <td className="px-2 py-3 text-sm text-start pl-4">
                    <div className="flex gap-2 flex-wrap">
                      <button
                        className={`px-3 py-3 rounded-lg text-xl font-bold bg-gray-50 text-[#288EC7] hover:bg-[#288EC7] hover:text-white`}
                      >
                        <CgScreen />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex items-center justify-between px-8 py-2 border-b border-r border-l">
          <p className="text-gray-600">
            Showing {data.length} of {data.length} Results
          </p>
          <div className="flex gap-1">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-full"
            >
              <FaAngleLeft />
            </button>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                className={`bg-gray-200 hover:bg-orange-600 text-gray-800 font-bold py-2 px-4 rounded-full ${
                  currentPage === index + 1 ? 'bg-orange-400 text-white' : ''
                }`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-full"
            >
              <FaAngleRight />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllUsers;
