import React, { useState, useEffect } from 'react';
import { IoSearch } from 'react-icons/io5';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';
import { CgScreen } from 'react-icons/cg';
import { useNavigate } from 'react-router-dom';
import api from '../../utils/api';

function AllTickets() {
  const [data, setData] = useState([]); // Data fetched from the API
  const [filteredData, setFilteredData] = useState([]); // Data to display after search
  const [searchQuery, setSearchQuery] = useState(''); // Search input value
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const rowsPerPage = 15; // Rows per page
  const totalPages = Math.ceil(filteredData.length / rowsPerPage); // Total pages
  const [selectAll, setSelectAll] = useState(false); // Select all checkbox state

  const navigate = useNavigate();

  // Fetch data from API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('/admin/tickts'); // Replace with your API URL
        const result = response;
        console.log('result', result);

        if (response.status === 200) {
          const formattedData = response.data.message.map((item) => ({
            id: item.id,
            selected: false,
            subjectTicket: `[Ticket#${item.id}]`,
            subjectName: item.subject,
            submittedBy: item.username,
            status: item.status === '1' ? 'Answered' : 'Pending', // Map status
            priority:
              item.priority.charAt(0).toUpperCase() + item.priority.slice(1), // Capitalize priority
            lastReply: item.last_reply,
          }));
          setData(formattedData);
          console.log('formattedData', formattedData);

          setFilteredData(formattedData);
        } else {
          console.error('Error fetching data:', response.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  // Handle search input
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = data.filter((row) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(query)
      )
    );
    setFilteredData(filtered);
    setCurrentPage(1); // Reset to first page after search
  };

  // Function to handle the "select all" checkbox click
  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setFilteredData(
      filteredData.map((row) => ({ ...row, selected: newSelectAll }))
    );
  };

  // Function to handle individual row checkbox click
  const handleRowCheckboxChange = (index) => {
    const updatedData = filteredData.map((row, rowIndex) =>
      rowIndex === index ? { ...row, selected: !row.selected } : row
    );
    setFilteredData(updatedData);
    setSelectAll(updatedData.every((row) => row.selected));
  };

  // Get current page data
  const currentPageData = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  // Handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleTicketsDetails = () => {
    navigate(`/supportticket/ticketdetails`);
  };

  return (
    <>
      <header className="flex justify-between px-6 py-2 mt-2">
        <div>
          <h2 className="text-xl font-bold">All Tickets</h2>
        </div>
      </header>
      <div className="mx-auto mt-2 p-4 w-[80vw]">
        <div className="flex justify-between items-center p-4 border-t border-l border-r">
          <div className="w-72 flex items-center gap-2 border px-4 rounded-lg">
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearch}
              className="w-full px-4 py-2 rounded-md outline-none"
              placeholder="Search..."
            />
            <IoSearch className="text-2xl" />
          </div>
        </div>
        <div className="overflow-x-auto w-full">
          <table className="table-auto w-full border-collapse border">
            <thead className="border-b">
              <tr>
                <th className="border-b">
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                    className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                  />
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Subject
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Submitted By
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Status
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Priority
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Last Reply
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {currentPageData.length > 0 ? (
                currentPageData.map((item, index) => (
                  <tr key={item.id} className="hover:bg-gray-100 border-b">
                    <td className="px-4 py-3 text-md text-center whitespace-nowrap">
                      <input
                        type="checkbox"
                        checked={item.selected}
                        onChange={() => handleRowCheckboxChange(index)}
                        className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                      />
                    </td>
                    <td className="px-2 py-3 text-md text-start pl-4">
                      <div className="flex gap-2">
                        <span>{item.subjectTicket}</span>
                        {item.subjectName}
                      </div>
                    </td>
                    <td className="px-2 py-3 text-md text-start pl-4">
                      {item.submittedBy}
                    </td>
                    <td className="px-2 py-3 text-md text-start pl-4">
                      <span
                        className={`px-5 py-3 rounded-lg border ${
                          item.status === 'Pending'
                            ? 'bg-yellow-50 border-yellow-600 text-yellow-600'
                            : item.status === 'Answered'
                            ? 'bg-green-50 border-green-600 text-green-600'
                            : 'bg-red-50 border-red-600 text-red-600'
                        }`}
                      >
                        {item.status}
                      </span>
                    </td>
                    <td className="px-2 py-3 text-md text-start pl-4">
                      <span
                        className={` ${
                          item.priority === 'High'
                            ? 'text-red-600 border-red-600 bg-red-50 px-5 py-3 rounded-lg border'
                            : item.priority === 'Medium'
                            ? 'text-yellow-600 border-yellow-600 bg-green-50 px-5 py-3 rounded-lg border'
                            : 'text-cyan-600 border-cyan-600 bg-cyan-50 px-5 py-3 rounded-lg border'
                        }`}
                      >
                        {item.priority}
                      </span>
                    </td>
                    <td className="px-2 py-3 text-md text-start pl-4">
                      {item.lastReply}
                    </td>
                    <td className="px-2 py-3 text-sm text-start pl-4">
                      <div className="flex gap-2 flex-wrap">
                        <button
                          onClick={handleTicketsDetails}
                          className={`px-3 py-3 rounded-lg text-xl font-bold bg-gray-50 text-[#288EC7] hover:bg-[#288EC7] hover:text-white`}
                        >
                          <CgScreen />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="7" // Set the number of columns in the table
                    className="text-center py-4 text-gray-500"
                  >
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="flex items-center justify-between px-8 py-2 border-b border-r border-l">
          <p className="text-gray-600">
            Showing {currentPageData.length} of {filteredData.length} Results
          </p>
          <div className="flex gap-1">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-full"
            >
              <FaAngleLeft />
            </button>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                className={`bg-gray-200 hover:bg-orange-600 text-gray-800 font-bold py-2 px-4 rounded-full ${
                  currentPage === index + 1 ? 'bg-orange-600 text-white' : ''
                }`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-full"
            >
              <FaAngleRight />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllTickets;
