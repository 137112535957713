import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const PaymentDetail = () => {
  const { id } = useParams(); // Fetch the payment ID from the URL
  const [paymentData, setPaymentData] = useState(null);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [remark, setRemark] = useState('');

  useEffect(() => {
    // Fetch payment details by ID
    const fetchPaymentDetails = async () => {
      try {
        const response = await axios.get(`/api/payments/${id}`);
        setPaymentData(response.data);
      } catch (error) {
        console.error('Error fetching payment details:', error);
      }
    };

    fetchPaymentDetails();
  }, [id]);

  const handleRejectOpenModal = () => {
    setIsRejectModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsRejectModalOpen(false);
  };

  if (!paymentData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-6">
      <h2 className="text-2xl font-bold mb-4">Payment Details</h2>
      <div className="grid grid-cols-2 gap-4">
        <p>
          <strong>Date:</strong> {paymentData.date}
        </p>
        <p>
          <strong>Transaction Number:</strong> {paymentData.transactionNumber}
        </p>
        <p>
          <strong>Username:</strong> {paymentData.username}
        </p>
        <p>
          <strong>Method:</strong> {paymentData.method}
        </p>
        <p>
          <strong>Amount:</strong> ${paymentData.amount}
        </p>
        <p>
          <strong>Charge:</strong> ${paymentData.charge}
        </p>
        <p>
          <strong>After Charge:</strong> ${paymentData.afterCharge}
        </p>
        <p>
          <strong>Status:</strong> {paymentData.status}
        </p>
      </div>
      <div className="flex gap-4 mt-6">
        <button
          onClick={handleRejectOpenModal}
          className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
        >
          Reject Payment
        </button>
        <button
          onClick={() => console.log('Payment approved')}
          className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
        >
          Approve Payment
        </button>
      </div>
      {isRejectModalOpen && (
        <div className="modal">
          <h3>Reject Payment</h3>
          <textarea
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
            placeholder="Enter remark"
            className="w-full p-2 border"
          />
          <button onClick={handleCloseModal}>Cancel</button>
          <button onClick={() => console.log('Rejected with remark:', remark)}>
            Confirm
          </button>
        </div>
      )}
    </div>
  );
};

export default PaymentDetail;
